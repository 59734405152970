* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:not(.highlighted) > pre {
    background: #f6ce84;
    padding: .5rem;
    border-radius: .4rem;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


pre > code {
  padding: 0;
}

html {
  background-color: #f3a1f3;
  line-height: 1.5;
}

p {
  margin: 1rem 0;
}

ul {
  padding-left: 2rem;
}


a {
  text-decoration: none;
  color: #034c51;
}

a:visited {
  color: #046268;
}